import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import williamImage from "../images/william.png"
import instagramWhite from "../images/instagram.svg"
import linkedIn from "../images/linked-in.svg"

const AnneliesPage = () => (
  <Layout useWhiteHeader useBlueBackground useHeaderBackground={false}>
    <SEO title="Annelies" />
    <section className="section container ">
      <img src={williamImage} alt="Foto Annelies" />
      <div className="container__content">
        <h2>Annelies</h2>
        <small className="sub-title">~ of (warme) William.</small>
        <p>
          (bijna) dertiger • woont in Kortrijk • getrouwd met de vrouw van haar
          leven • freelance digitaal strategist • mede-oprichter van nucompany •
          ontwerpt digitale oplossingen die er toe doen • podcaster •
          wereldreiziger • boekenverslaafd • dromer en doener • heeft 'doen en
          leren' als motto • is steeds op zoek naar verbinding • houdt van diepe
          babbels • eet zo plantaardig mogelijk • vindt vrijheid en
          authenticiteit suuuuper belangrijk • heeft buikgevoel als kompas • kan
          moeilijk nee zeggen • krijgt energie van lachen, spelen, mensen,
          alleen zijn, mediteren, surfen, loopjes, voetbal, leren, lezen en
          roséééé.
        </p>
        <div className="container__content__socials">
          <a
            href="https://www.instagram.com/anneliess_c"
            target="__blank"
            rel="noopener noreferrer"
          >
            <img src={instagramWhite} alt="Instagram logo" />
          </a>
          <a
            href="https://www.linkedin.com/in/annelies-callewaert"
            target="__blank"
            rel="noopener noreferrer"
          >
            <img src={linkedIn} alt="Linked in logo" />
          </a>
        </div>
      </div>
    </section>
  </Layout>
)

export default AnneliesPage
